import React from "react"
import { Outlet, Navigate } from "react-router-dom"
import { useAuthGuard } from "@Hooks"
import { Context } from "src/context"

const AuthGuard = ({ children }: { children?: JSX.Element }) => {
  const { token } = React.useContext(Context)
  const { isValidToken } = useAuthGuard(token)

  if (isValidToken()) {
    return children ? children : <Outlet />
  }

  return <Navigate to={"/wizard/invalid?type=expired"} />
}

export default AuthGuard
