export default {
  translations: {
    page: {
      finished: {
        developedBy: "Desenvolvido por",
        aso: {
          title: "Agradecemos pelo envio do documento",
          subtitle: "Agora é só aguardar as próximas instruções do RH",
          description:
            "Você completou mais uma etapa do seu processo de admissão totalmente digital. Agora nosso time irá revisar o documento enviado. Nos encontraremos em breve 😊",
        },
        resignationAso: {
          title: "Tudo pronto!",
          subtitle: "Aguarde as próximas instruções do RH",
          description:
            "Agradecemos pelo envio do exame demissional. Agora nosso time irá revisar o documento enviado.",
        },
        documents: {
          title: "Agradecemos pelo envio dos seus documentos",
          subtitle: "Agora é só aguardar as próximas instruções do RH 😊",
          description:
            "Você completou mais uma etapa do seu processo de admissão totalmente digital. Agora nosso time irá revisar todos os documentos enviados. Enquanto isso adiantamos que estamos cada vez mais animados pela sua chegada, nos encontraremos em breve",
        },
        signing: {
          title: "Tudo pronto!",
          subtitle: "Logo você receberá um e-mail de confirmação.",
          description:
            "Agradecemos pelo envio da sua assinatura. Agora é só aguardar as próximas instruções do RH.",
        },
      },
      resignationAso: {
        requiredField: "Campo requirido",
        startStep: {
          typeSend: "Envie",
          typeResent: "Reenvie",
          title: "{{type_of_send}} seu atestado de saúde ocupacional",
          description: "Clique para prosseguir e enviar o documento.",
          buttonNextSend: "Enviar exame",
          buttonNextResent: "Reenviar exame",
        },
        content: {
          errorValidation: "Por favor preencha todos os campos obrigatórios",
          errorHttp: "Algo aconteceu ao enviar o exame demissional",
          title: "Exame demissional",
          description:
            "Envie o resultado do seu exame admissional para dar seguimento ao processo demissional.",
          subtitle: "Atestado de saúde ocupacional",
          subtitleDescription:
            "Certifique-se de que o documento anexo está legível e que nenhuma informação foi cortada da foto.",
          guideLabel: "Guia do exame",
          dateLabel: "Data do exame",
          uploadLabel: "Foto do ASO",
          buttonNext: "Enviar documento",
        },
      },
      breadcrumbSigning: {
        firstStepLabel: "Visualizar contrato",
        secondStepLabel: "Confirmar dados",
        secondStepSmallLabel: "Confirmar dados",
        thirdStepLabel: "Assinar contrato",
        thirdStepSmallLabel: "Assinar contrato",
      },
      breadcrumb: {
        pj: {
          firstStepLabel: "Dados básicos",
          secondStepLabel: "Documentos pessoais e jurídicos",
          secondStepSmallLabel: "Docs pessoais",
          thirdStepLabel: "Documentos de dependentes",
          thirdStepSmallLabel: "Docs de dependentes",
          fourthStepLabel: "Confirmação",
        },
        clt: {
          firstStepLabel: "Dados básicos",
          secondStepLabel: "Documentos da pessoa",
          secondStepSmallLabel: "Docs pessoais",
          thirdStepLabel: "Documentos de dependentes",
          thirdStepSmallLabel: "Docs de dependentes",
          fourthStepLabel: "Confirmação",
        },
        internship: {
          firstStepLabel: "Dados básicos",
          secondStepLabel: "Documentos da pessoa",
          secondStepSmallLabel: "Docs pessoais",
          thirdStepLabel: "Documentos de dependentes",
          thirdStepSmallLabel: "Docs de dependentes",
          fourthStepLabel: "Confirmação",
        },
      },
      basicDataStep: {
        titleLabel: "Dados básicos",
        cpfError: "Coloque um CPF válido",
      },
      dependentsStep: {
        titleLabel: "Documentos de dependentes",
      },
      candidateStep: {
        titleLabel: "Documentos do candidato",
      },
      confirmationStep: {
        titleLabel: "Confira seus dados e documentos",
      },
      signing: {
        start: {
          titles: {
            target: {
              clt: "{{name}}, hora de assinar seu contrato de trabalho!",
              pj: "{{name}}, hora de assinar seu contrato de prestação de serviço!",
              internship:
                "{{name}}, hora de assinar seu termo de compromisso de estágio!",
              resignation:
                "{{name}}, confira e assine seus documentos rescisórios!",
            },
            others: {
              clt: "Hora de assinar o contrato do(a) candidato(a) {{name}}",
              pj: "Hora de assinar o contrato do(a) prestador(a) de serviço {{name}}",
              internship:
                "Hora de assinar o termo de compromisso de estágio do(a) candidato(a) {{name}}",
              resignation:
                "Hora de assinar os documentos rescisórios do(a) {{name}}",
            },
          },
          expireDate:
            "Por favor, analise e assine o documento até às {{hour}}h do dia {{date}}",
          description: "Você recebeu um documento para análise e assinatura.",
          buttonLabel: "Analisar documento",
          developedBy: "Desenvolvido por",
        },
        confirmation: {
          title: "Dados da sua proposta de trabalho",
          description:
            "Vem aí o match perfeito? Estamos na torcida que sim! 🌟 Aqui você encontra todos os detalhes da sua proposta de trabalho. Em caso de dúvidas, procure o RH.",
          buttonConfirm: "Aceitar proposta",
          buttonDeny: "Recusar proposta",
          role: "Cargo",
          department: "Departamento",
          hiringType: "Tipo de vínculo",
          salary: "Remuneração",
          hiringDate: "Data prevista da admissão",
        },
        accepted: {
          title: "Oba, estamos super animados em ter você com a gente! ",
          subTitle: "Aguarde as próximas instruções do RH 😊",
          description:
            "Ficamos muito felizes com o seu aceite e esperamos ter você em breve conosco. Logo, logo o RH passará mais informações sobre as próximas etapas do processo de admissão, combinado?",
        },
        denied: {
          title: "Poxa, sentimos muito pela sua recusa",
          subTitle: "Esperamos contar com o seu aceite em outra oportunidade!",
          description:
            "Agradecemos muito sua participação e dedicação até aqui. Adoramos te conhecer e desejamos muito sucesso na sua jornada. Em caso de dúvidas não deixe de entrar em contato conosco.",
        },
      },
      proposal: {
        errorMessage: "Houve um problema ao aceitar a carta proposta.",
        start: {
          proposalTitle: "Parabéns",
          proposalSubtitle: "Sua proposta de trabalho chegou 🎉",
          description:
            "É hora de celebrar, afinal, você tem uma nova carta proposta! Para visualizar sua oferta completa clique no botão abaixo e em caso de dúvidas, converse com o RH. E não se esqueça de dar uma resposta dentro do prazo estabelecido, combinado? Boa sorte!",
          buttonLabel: "Visualizar proposta",
          developedBy: "Desenvolvido por",
        },
        confirmation: {
          title: "Confira todos os detalhes da sua nova proposta de trabalho",
          description:
            "Vem aí o match perfeito? Estamos na torcida que sim! 🌟 Aqui você encontra todos os detalhes da sua proposta de trabalho. Em caso de dúvidas, procure o RH.",
          buttonConfirm: "Aceitar proposta",
          buttonDeny: "Recusar proposta",
          role: "Cargo",
          department: "Departamento",
          group: "Grupo",
          hiringType: "Tipo de vínculo",
          salary: "Remuneração",
          hiringDate: "Data prevista da admissão",
        },
        accepted: {
          title: "Oba, estamos super animados em ter você com a gente! ",
          subTitle: "Aguarde as próximas instruções do RH 😊",
          description:
            "Ficamos muito felizes com o seu aceite e esperamos ter você em breve conosco. Logo, logo o RH passará mais informações sobre as próximas etapas do processo de admissão, combinado?",
        },
        denied: {
          title: "Poxa, sentimos muito pela sua recusa",
          subTitle: "Esperamos contar com o seu aceite em outra oportunidade!",
          description:
            "Agradecemos muito sua participação e dedicação até aqui. Adoramos te conhecer e desejamos muito sucesso na sua jornada. Em caso de dúvidas não deixe de entrar em contato conosco.",
        },
      },
    },
    error: {
      defaultError:
        "Algo inesperado aconteceu. Por favor, tente novamente mais tarde ou entre em contato com o suporte se o problema persistir.",
    },
  },
}
