import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Container, Avatar, LogoArea } from "./styles"
import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import Logo from "../../../../assets/logo_flash.svg"
import { FlowCategory, FlowSubcategory, SignType } from "server/src/types"
import { Context } from "src/context"
import { createSegmentTrack, trackList } from "@Utils/segment"
import i18n from "src/i18n"
import { firstCapitalLetter } from "@Utils/index"
import { Box } from "@Components"
import { useTheme } from "styled-components"

const types = [
  FlowCategory.resignation,
  FlowSubcategory.clt,
  FlowSubcategory.internship,
  FlowSubcategory.pj,
] as const

type PossiblesFlows = typeof types[number]

export type ContentProps = {
  targetName: string
  signerType: SignType
  expireDate?: { formattedDate: string; formattedTime: string }
}

const othersTitleByType: Record<PossiblesFlows, (name: string) => string> = {
  [FlowCategory.resignation]: (name: string) =>
    i18n.t("page.signing.start.titles.others.resignation", { name }),
  [FlowSubcategory.clt]: (name: string) =>
    i18n.t("page.signing.start.titles.others.clt", { name }),
  [FlowSubcategory.internship]: (name: string) =>
    i18n.t("page.signing.start.titles.others.internship", { name }),
  [FlowSubcategory.pj]: (name: string) =>
    i18n.t("page.signing.start.titles.others.pj", { name }),
}

const targetTitleByType: Record<PossiblesFlows, (name: string) => string> = {
  [FlowCategory.resignation]: (name: string) =>
    i18n.t("page.signing.start.titles.target.resignation", {
      name,
    }),
  [FlowSubcategory.clt]: (name: string) =>
    i18n.t("page.signing.start.titles.target.clt", { name }),
  [FlowSubcategory.internship]: (name: string) =>
    i18n.t("page.signing.start.titles.target.internship", { name }),
  [FlowSubcategory.pj]: (name: string) =>
    i18n.t("page.signing.start.titles.target.pj", { name }),
}

export const Content = ({
  targetName,
  signerType,
  expireDate,
}: ContentProps) => {
  const navigate = useNavigate()
  const { subcategory, category } = useContext(Context)

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })
  const theme = useTheme()

  const isResignation = category === FlowCategory.resignation
  const signatureFlowType: PossiblesFlows = isResignation
    ? FlowCategory.resignation
    : (subcategory as FlowSubcategory)

  const isTarget = [SignType.candidate, SignType.employee].includes(signerType)

  const getTitleFn = isTarget
    ? targetTitleByType[signatureFlowType]
    : othersTitleByType[signatureFlowType]

  const name = firstCapitalLetter(targetName?.split(" ")[0])

  return (
    <>
      <Container>
        <Avatar>{targetName.substring(0, 1).toUpperCase()}</Avatar>

        <Typography variant={"headline6"} variantColor={theme.colors.primary}>
          {getTitleFn(name)}
        </Typography>

        <Box
          flexDirection="column"
          alignItems="center"
          gap="xs4"
          $mt="xs2"
          $mb="xs"
        >
          {isResignation && (
            <Typography variant="body3" variantColor={theme.colors.neutral[75]}>
              {t("signing.start.description")}
            </Typography>
          )}

          {!!expireDate && (
            <Typography variant="body3" variantColor={theme.colors.neutral[75]}>
              {t("signing.start.expireDate", {
                date: expireDate.formattedDate,
                hour: expireDate.formattedTime,
              })}
            </Typography>
          )}
        </Box>

        <Button
          onClick={() => {
            const type = isTarget ? "employee" : "company"
            const track = trackList?.[subcategory]?.[type]?.analyzeDocument

            if (track)
              createSegmentTrack({
                track: track,
              })

            navigate(`../wizard/signing/visualizeContract`, {
              replace: true,
            })
          }}
          variant="primary"
          size="large"
          style={{ alignSelf: "center" }}
        >
          {t("signing.start.buttonLabel")}
        </Button>
      </Container>

      <LogoArea>
        {t("signing.start.developedBy")}
        <Logo style={{ marginLeft: "8px" }} />
      </LogoArea>
    </>
  )
}
