import styled from "styled-components"

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors.neutral[95]};
`
