import React from "react"
import { useNavigate } from "react-router-dom"
import { Container, HeaderWrapper, CanvasWrappper } from "./styles"
import {
  Button,
  IconButton,
  Icons,
  useMediaQuery,
  Loader,
} from "@flash-tecnologia/hros-web-ui-v2"
import { SignType } from "server/src/types"
import { Context } from "src/context"
import { createSegmentTrack, trackList } from "@Utils/segment"
import { removeDropzoneTimestamp } from "@Utils/index"
import { FooterBar } from "../../components"
import { signingContext } from "../.."
import { Box } from "@Components"

const pdfjsLib = require("pdfjs-dist")
const pdfjsWorker = require("pdfjs-dist/build/pdf.worker.entry")

const pdfLib = require("pdf-lib")
const download = require("downloadjs")

const VisualizeContract = () => {
  const [isLoadingPdf, setIsLoadingPdf] = React.useState<boolean>(false)
  const [currentPage, setCurrentPage] = React.useState()
  const [totalPages, setTotalPages] = React.useState(0)
  const [nextHandleClick, setNextHandleClick] = React.useState(() => () => {})
  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)
  const [name, setName] = React.useState("")
  const [pageIndex, setPageIndex] = React.useState(-1)
  const [pages, setPages] = React.useState<any>([])
  const [scale, setScale] = React.useState(1)

  const { type: userType, subcategory } = React.useContext(Context)
  const { signData } = React.useContext(signingContext)
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  const isMobile = useMediaQuery("(max-width: 968px)")
  const navigate = useNavigate()

  React.useEffect(() => {
    if (pageIndex + 1 == pages.length) {
      setCurrentPage(pages[pageIndex])
      setNextHandleClick(() => () => {
        if (track) createSegmentTrack({ track: track.pdfViewContinue })
        navigate(`../wizard/signing/confirmData`, {
          replace: true,
        })
      })
      return
    }

    if (pages.length && pageIndex >= 0) {
      setCurrentPage(pages[pageIndex])
      setNextHandleClick(() => () => {
        if (track) createSegmentTrack({ track: track.pdfViewContinue })
        setPageIndex(pageIndex + 1)
      })
      return
    }
  }, [pages, pageIndex])

  React.useEffect(() => {
    async function loadPdf() {
      try {
        pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker
        const pdf = await pdfjsLib.getDocument(file).promise

        const promises = Array(pdf.numPages)
          .fill(0)
          .map(async (_, index) => {
            const page = await pdf.getPage(index + 1)
            return page
          })

        const pages = await Promise.all(promises)
        const lastIndexName = key.lastIndexOf("/")
        const nameOfArchive =
          removeDropzoneTimestamp(key?.substring(lastIndexName + 1)) ||
          "contrato.pdf"

        setName(nameOfArchive)
        setPages(pages)
        setPageIndex(0)
        setTotalPages(pages.length)
      } catch (e) {
        console.log(e)
        navigate(`../wizard/invalid?type=corrupted`)
      }
    }

    const file = signData.contractLastOutput.value
    const key = signData.contractLastOutput.key

    if (file) {
      setIsLoadingPdf(true)
      loadPdf().finally(() => setIsLoadingPdf(false))
    }
  }, [signData])

  React.useEffect(() => {
    const renderPage = (p: any) => {
      const _page = p

      if (_page) {
        const context = canvasRef.current?.getContext("2d")
        const viewport = _page.getViewport({ scale: scale })
        setWidth(viewport.width)
        setHeight(viewport.height)

        if (context) {
          _page.render({
            canvasContext: canvasRef.current?.getContext("2d"),
            viewport,
          })
        }
      }
    }

    if (currentPage !== null) {
      renderPage(currentPage)
    }
  }, [currentPage, scale])

  const savePDF = async () => {
    if (!pages.length) return
    const file = signData.contractLastOutput.value
    if (!file) return

    let pdfDoc = {} as any

    const existingPdfBytes = await fetch(file).then((res) => res.arrayBuffer())
    const bytes = new Uint8Array(existingPdfBytes)

    pdfDoc = await pdfLib.PDFDocument.load(bytes)

    const pdfBytes = await pdfDoc.save()
    download(pdfBytes, name, "application/pdf")
  }

  const trackUserList = React.useMemo(
    () => ({
      [SignType.candidate]: trackList?.[subcategory]?.employee,
      [SignType.company]: trackList?.[subcategory]?.company,
      [SignType.institution]: trackList?.[subcategory]?.institution,
    }),
    [subcategory]
  )

  const track = trackUserList?.[userType]

  return (
    <>
      <Container>
        <HeaderWrapper>
          {!isMobile && (
            <Box alignItems="center" justifyContent="flex-start">
              <IconButton
                size="small"
                variant="line"
                icon="IconZoomOut"
                onClick={() => setScale(scale - 0.25)}
                style={{ marginRight: "16px" }}
              />

              <IconButton
                size="small"
                variant="line"
                icon="IconZoomIn"
                style={{ marginRight: "16px" }}
                onClick={() => setScale(scale + 0.25)}
              />
            </Box>
          )}

          <Box
            alignItems="center"
            justifyContent={isMobile ? "flex-start" : "center"}
          >
            <IconButton
              size="small"
              variant="line"
              icon="IconChevronLeft"
              disabled={pageIndex - 1 < 0}
              onClick={() => {
                setCurrentPage(pages[pageIndex - 1])
                setPageIndex(pageIndex - 1)
              }}
            />
            <span style={{ marginRight: "16px", marginLeft: "16px" }}>
              {pageIndex + 1}/{pages.length}
            </span>
            <IconButton
              size="small"
              variant="line"
              icon="IconChevronRight"
              disabled={pageIndex + 2 > pages.length}
              onClick={() => {
                if (track) createSegmentTrack({ track: track.pdfViewNextPage })
                setCurrentPage(pages[pageIndex + 1])
                setPageIndex(pageIndex + 1)
              }}
            />
          </Box>

          <Box alignItems="center" justifyContent="flex-end">
            <Button
              size="small"
              variant="primary"
              disabled={isLoadingPdf}
              onClick={() => {
                if (track) createSegmentTrack({ track: track.pdfViewDownload })
                savePDF()
              }}
            >
              Baixar documento
              <Icons name="IconDownload" fill="transparent" />
            </Button>
          </Box>
        </HeaderWrapper>

        {isLoadingPdf && (
          <Box $height={"100%"} justifyContent="center" alignItems="center">
            <Loader variant="secondary" size="large" />
          </Box>
        )}

        <CanvasWrappper>
          <canvas ref={canvasRef} width={width} height={height} />
        </CanvasWrappper>
      </Container>

      <FooterBar
        isLoading={isLoadingPdf}
        currentPage={pageIndex}
        totalPages={totalPages}
        nextHandleClick={nextHandleClick}
      />
    </>
  )
}

export default VisualizeContract
