import { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { uploadHelper } from "@flash-hros/utility"
import { toPng } from "html-to-image"
import html2canvas from "html2canvas"
import { signingContext } from "../.."
import { HiringLimitDateEnum, SignType, SignatureFile } from "server/src/types"
import { Context } from "src/context"
import { dataURLtoFile } from "@Utils/index"
import { trackList } from "@Utils/segment"
import dispatchToast from "@Utils/dispatchToast"
import { trpc } from "src/api/trpc"
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"
import { Mobile } from "./Mobile"
import { Desktop } from "./Desktop"
import i18n from "src/i18n"

const SignData = () => {
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<any>([])

  const navigate = useNavigate()

  const globalContext = useContext(Context)
  const { confirmationData, signData } = useContext(signingContext)

  const isMobile = useMediaQuery("(max-width: 768px)")

  const { mutateAsync: submitSignContract } =
    trpc.wizard.submitSignContractV2.useMutation({
      onError: (err) => {
        dispatchToast({
          type: "error",
          content:
            err.data?.userFriendlyError.message || i18n.t("error.defaultError"),
        })
      },
    })

  useEffect(() => {
    if (
      !confirmationData?.documentNumber ||
      !confirmationData?.email ||
      !confirmationData?.name
    ) {
      dispatchToast({
        type: "error",
        content: "Por favor preencha os dados",
      })
      navigate(`../wizard/signing/confirmData`)
    }
  }, [confirmationData])

  async function handleSigned(uploadedFile: SignatureFile) {
    if (
      !confirmationData?.name ||
      !confirmationData.email ||
      !confirmationData.documentNumber
    ) {
      dispatchToast({
        variant: "toast",
        type: "error",
        title: "Algumas informações não foram encontradas.",
        description: "Por favor, volte para a etapa de confirmar dados.",
      })
      return
    }

    const { routeRedirect } = await submitSignContract({
      cardId: signData.cardId,
      category: globalContext.category,
      name: globalContext.name,
      signType: globalContext.type as SignType,
      signatureFile: uploadedFile,
      confirmationData: confirmationData,
    })

    navigate(`../wizard/signing/${routeRedirect}`, {
      replace: true,
    })
  }

  const uploadSignatureFile = async ({
    name,
    file,
  }: {
    name: any
    file: any
  }): Promise<SignatureFile> => {
    const foundSignature = signData.contractSignatures.find(
      (signInfo) =>
        signInfo.type === globalContext.type &&
        signInfo.name === globalContext.name
    )
    if (!foundSignature) throw new Error("No signature found")

    const userId =
      globalContext.category === "hiring"
        ? foundSignature.candidateId
        : foundSignature.employeeId

    const url = `${
      globalContext.companyId +
      "/" +
      userId +
      "/" +
      signData.cardId +
      "/signature"
    }`

    const { key, path } = await uploadHelper({
      key: url,
      file,
      fileName: name,
      module: "employee-pii",
      externalToken: globalContext.token,
    })

    return { key, path, origin: "internal" }
  }

  const addTextField = async () => {
    const response = await html2canvas(
      document.getElementById("textToUpload") as HTMLElement,
      {
        onclone: function (doc: any) {
          const spanToUnhidden = doc.getElementById("textToUpload")
          spanToUnhidden.style.display = "block"
        },
      }
    )
    if (!response) return

    const dataURL = response.toDataURL()
    const file = dataURLtoFile(dataURL, "signature.png")
    const uploadedFile = await uploadSignatureFile({
      name: `signature_${globalContext.type}_${globalContext.name}.png`,
      file,
    })

    await handleSigned(uploadedFile)
  }

  const updateText = ({ id, text }: any) => {
    const allObjects = attachments.map((attachment) =>
      attachment.id === id ? { ...attachment, text } : attachment
    )

    setAttachments(allObjects)
  }

  const addDrawing = async () => {
    const htmlText = document.getElementById("signSvg") as HTMLElement
    const base64 = await toPng(htmlText)
    if (!base64) return

    const res = await fetch(base64)
    const blob = await res.blob()
    const file = new File([blob], "signature", { type: "image/png" })

    const uploadedFile = await uploadSignatureFile({
      name: `signature_${globalContext.type}_${globalContext.name}.png`,
      file,
    })

    await handleSigned(uploadedFile)
  }

  const updateDrawing = ({
    id,
    originWidth,
    originHeight,
    path,
    paths,
  }: any) => {
    const object = {
      id,
      path,
      paths,
      originWidth,
      originHeight,
      width: originWidth,
      height: originHeight,
      scale: 1,
    }

    const allObjects = attachments.map((attachment) =>
      attachment.id === id ? { ...attachment, ...object } : attachment
    )

    setAttachments(allObjects)
  }

  const handleSubmit = (callback: () => Promise<void>) => {
    setIsSubmiting(true)
    callback().finally(() => setIsSubmiting(false))
  }

  const trackUserList = {
    ["candidate"]: trackList?.[globalContext.subcategory]?.employee,
    ["company"]: trackList?.[globalContext.subcategory]?.company,
    ["institution"]: trackList?.[globalContext.subcategory]?.institution,
  }

  const track = trackUserList?.[globalContext.type]

  return isMobile ? (
    <Mobile
      attachments={attachments}
      addDrawing={() => handleSubmit(addDrawing)}
      addTextField={() => handleSubmit(addTextField)}
      isLoading={isSubmiting}
      updateDrawing={updateDrawing}
      updateText={updateText}
      track={track}
    />
  ) : (
    <Desktop
      attachments={attachments}
      addDrawing={() => handleSubmit(addDrawing)}
      addTextField={() => handleSubmit(addTextField)}
      isLoading={isSubmiting}
      updateDrawing={updateDrawing}
      updateText={updateText}
      track={track}
    />
  )
}

export default SignData
