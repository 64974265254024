import { useContext } from "react"
import { useNavigate } from "react-router-dom"

import { useFormik } from "formik"
import * as yup from "yup"

import {
  Container,
  RightContainer,
  LeftContainer,
  RightSubContainer,
  ActionFooterContainer,
} from "./styles"

import {
  TextField,
  Typography,
  Icons,
  LinkButton,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2"
import { createSegmentTrack, trackList } from "@Utils/segment"
import { Context } from "src/context"
import { ConfirmationDataType, signingContext } from "../.."
import { SignType } from "server/src/types"

const ConfirmData = () => {
  const navigate = useNavigate()
  const { subcategory, type: userType } = useContext(Context)
  const { confirmationData, handleSetConfirmationData } =
    useContext(signingContext)

  const trackUserList = {
    [SignType.candidate]: trackList?.[subcategory]?.employee || "",
    [SignType.company]: trackList?.[subcategory]?.company || "",
    [SignType.institution]: trackList?.[subcategory]?.institution,
  }

  const track = trackUserList?.[userType]

  const validationSchema = yup.object({
    name: yup.string().required("Favor digitar o seu nome"),
    documentNumber: yup
      .string()
      .min(14, "Por favor informe um CPF valido")
      .required("Favor digitar o seu cpf"),
    email: yup
      .string()
      .email("Por favor preencha um email valido")
      .required("Favor digitar o seu email"),
  })

  const formik = useFormik({
    initialValues: {
      name: confirmationData?.name || "",
      documentNumber: confirmationData?.documentNumber || "",
      email: confirmationData?.email || "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ name, documentNumber, email }) => {
      if (track) createSegmentTrack({ track: track.confirmDataContinue })
      handleSetConfirmationData({ name, documentNumber, email })
      navigate(`../wizard/signing/signData`)
    },
  })

  return (
    <Container>
      <LeftContainer>
        <Typography variant="headline7" variantColor="#F20D7A">
          Confirmar dados
        </Typography>
        <Typography
          variant="body3"
          variantColor="#83727D"
          style={{
            marginTop: "24px",
          }}
        >
          Confirme seus dados para prosseguir com a assinatura do contrato.
        </Typography>
      </LeftContainer>
      <RightContainer>
        <RightSubContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography variant="headline8" style={{ color: "#53464F" }}>
              Dados pessoais
            </Typography>
            <div
              style={{
                marginTop: "32px",
                marginBottom: "24px",
              }}
            >
              <TextField
                id={"name"}
                name={"name"}
                label={"Nome Completo"}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={
                  <>{formik.touched.name ? formik.errors.name : ""}</>
                }
                style={{
                  width: "100%",
                }}
              />
            </div>
            <div
              style={{
                marginBottom: "24px",
              }}
            >
              <TextField
                id={"documentNumber"}
                name={"documentNumber"}
                label={"CPF"}
                imaskProps={{ mask: "000.000.000-00" }}
                value={formik.values.documentNumber}
                inputProps={{ maxLength: 14 }}
                onChange={formik.handleChange}
                error={
                  formik.touched.documentNumber &&
                  Boolean(formik.errors.documentNumber)
                }
                helperText={
                  <>
                    {formik.touched.documentNumber
                      ? formik.errors.documentNumber
                      : ""}
                  </>
                }
                style={{ width: "100%" }}
              />
            </div>
            <TextField
              id={"email"}
              name={"email"}
              label={"E-mail"}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                <>{formik.touched.email ? formik.errors.email : ""}</>
              }
            />
          </div>
        </RightSubContainer>
        <ActionFooterContainer>
          <LinkButton
            variant="primary"
            onClick={() => {
              if (track) createSegmentTrack({ track: track?.confirmDataReturn })

              navigate(`../wizard/signing/visualizeContract`, {
                replace: true,
              })
            }}
          >
            Voltar
          </LinkButton>

          <Button
            type={"submit"}
            variant="primary"
            size="large"
            onClick={() => formik.handleSubmit()}
          >
            Continuar
            <Icons
              name="IconArrowRight"
              style={{
                fill: "transparent",
                color: "#fff",
                marginLeft: "8px",
              }}
            />
          </Button>
        </ActionFooterContainer>
      </RightContainer>
    </Container>
  )
}

export default ConfirmData
