import { useTranslation } from "react-i18next"
import {
  Container,
  Description,
  Avatar,
  LogoArea,
  StyledPOneLine,
  UploadFileName,
  UploadIconContainer,
  UploadPreview,
  UploadPreviewArea,
} from "./styles"
import Logo from "../../../../assets/logo_flash.svg"
import { IconButton, Icons } from "@flash-tecnologia/hros-web-ui-v2"
import { trackList, createSegmentTrack } from "@Utils/segment"
import { useContext } from "react"
import { SignType } from "server/src/types"
import { Context } from "src/context"
import { removeDropzoneTimestamp } from "@Utils/index"
import { signingContext } from "../.."

const Download = () => {
  const { type: userType, subcategory } = useContext(Context)
  const { signData } = useContext(signingContext)

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const trackUserList = {
    [SignType.candidate]: trackList?.[subcategory]?.employee,
    [SignType.company]: trackList?.[subcategory]?.company,
  }

  const track = trackUserList?.[userType]

  const renderUploadComponent = () => {
    const { value = "", key = "" } = signData.contractLastOutput

    const lastIndexName = key ? key.lastIndexOf("/") : ""
    const nameOfArchive =
      key && lastIndexName
        ? removeDropzoneTimestamp(key.substring(lastIndexName + 1))
        : "Nome não identificado"

    return (
      <div
        role="presentation"
        onClick={async (e) => {
          e.stopPropagation()
        }}
      >
        <UploadPreview>
          <UploadPreviewArea>
            <UploadIconContainer>
              <Icons name={"IconFile"} style={{ fill: "transparent" }} />
            </UploadIconContainer>
            <UploadFileName>
              <StyledPOneLine numberOfLines={1}>{nameOfArchive}</StyledPOneLine>
            </UploadFileName>
          </UploadPreviewArea>
          <IconButton
            size="medium"
            variant="filled"
            icon="IconDownload"
            onClick={async () => {
              if (track)
                createSegmentTrack({ track: track.signContractDownload })

              const readFile = await fetch(value)
              const fileToArrayBuffer = await readFile.arrayBuffer()
              const res = URL.createObjectURL(new Blob([fileToArrayBuffer]))
              const a = document.createElement("a")
              a.href = res
              a.download = nameOfArchive
              a.click()
            }}
          />
        </UploadPreview>
      </div>
    )
  }
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <Avatar>
          <Icons
            name="IconMoodHappy"
            style={{
              width: "64px",
              height: "64px",
              fill: "transparent",
              color: "#fff",
            }}
          />
        </Avatar>
        <div style={{ maxWidth: "400px" }}>
          <Description variant={"body3"}>
            <span>Confira o documento assinado em anexo.</span>
          </Description>
          {renderUploadComponent()}
        </div>
      </Container>
      <LogoArea>
        {t("signing.start.developedBy")}
        <Logo style={{ marginLeft: "8px" }} />
      </LogoArea>
    </div>
  )
}

export default Download
