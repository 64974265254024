import { PreviewField, ValidationAccordion } from "@Components"
import { useState } from "react"
import {
  Component,
  ConfirmationDependent,
  FieldType,
  FieldValue,
  HiringCard,
} from "server/src/types"
import EditDependentsModal from "./EditModal/EditDependents"

type ModalEdit = {
  isOpen: boolean
  dependent?: ConfirmationDependent
}

const RenderDependentMandatoryField = ({
  dependent,
}: {
  dependent: ConfirmationDependent
}) => {
  const mandatoryFields: FieldValue[] = [
    {
      candidateId: dependent.candidateId,
      component: Component.ShortText,
      enabled: true,
      fieldId: "kinship",
      customFieldId: "kinship",
      fieldType: FieldType.Text,
      value: dependent.kinship,
      placeholder: "Grau de parentesco",
      formGroupId: "basic-data",
      type: "base",
    },
    {
      candidateId: dependent.candidateId,
      component: Component.ShortText,
      enabled: true,
      fieldId: "name",
      customFieldId: "name",
      fieldType: FieldType.Text,
      value: dependent.name,
      placeholder: "Nome do dependente",
      formGroupId: "basic-data",
      type: "base",
    },
    {
      candidateId: dependent.candidateId,
      component: Component.DatePicker,
      enabled: true,
      fieldId: "birthDate",
      customFieldId: "birthDate",
      fieldType: FieldType.Text,
      value: dependent.birthDate,
      placeholder: "Data de nascimento",
      formGroupId: "basic-data",
      type: "base",
    },
  ]

  return (
    <ValidationAccordion title={"Dados básicos"} required={true}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 24,
        }}
      >
        {mandatoryFields.map((field, idx) => (
          <PreviewField key={idx} field={field} />
        ))}
      </div>
    </ValidationAccordion>
  )
}

const CandidateDependents = ({
  card,
  dependents,
}: {
  card: HiringCard
  dependents: ConfirmationDependent[]
}) => {
  const [editModal, setEditModal] = useState<ModalEdit>({
    isOpen: false,
  })

  return (
    <>
      {dependents.map((dependent, idx) => {
        return (
          <ValidationAccordion
            key={idx}
            title={dependent.name}
            required={true}
            handleEdit={async (e) => {
              e.stopPropagation()
              setEditModal({
                isOpen: true,
                dependent: dependent,
              })
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 24,
              }}
            >
              <RenderDependentMandatoryField dependent={dependent} />

              {dependent.documents.map((doc, idx) => {
                const isRequired = doc.fields.some((f) => f.fieldRule?.required)

                return (
                  <ValidationAccordion
                    key={idx}
                    title={doc.title}
                    description={doc.description}
                    required={isRequired}
                    status={isRequired ? "waiting" : "optional"}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 24,
                      }}
                    >
                      {doc.fields.map((field, index) => (
                        <PreviewField key={index} field={field} />
                      ))}
                    </div>
                  </ValidationAccordion>
                )
              })}
            </div>
          </ValidationAccordion>
        )
      })}

      {editModal.isOpen && editModal.dependent && (
        <EditDependentsModal
          isOpen={editModal.isOpen}
          dependent={editModal.dependent}
          card={card}
          handleClose={() => {
            setEditModal({
              isOpen: false,
            })
          }}
        />
      )}
    </>
  )
}

export default CandidateDependents
