import styled from "styled-components"

export const StepContainer = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  max-width: 1440px;
  flex: 1;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    margin-top: 40px;
  }
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin-top: 60px;
  }
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    margin-top: 250px;
  }
`

export const LoadingArea = styled.div`
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow: scroll;
`

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.neutral[95]};
`
