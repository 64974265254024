import { useMemo } from "react"
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"
import Desktop from "./Desktop"
import Mobile from "./Mobile"

export type HeaderProps = {
  companyName: string
  companyLogo?: string
}

const Header = (props: HeaderProps) => {
  const isMobile = useMediaQuery("(max-width: 768px)")

  const HeaderRender = useMemo(
    () => (isMobile ? <Mobile {...props} /> : <Desktop {...props} />),
    [isMobile]
  )

  return HeaderRender
}

export default Header
