import {
  TextField,
  Checkbox as CheckboxComponent,
  Typography,
  SelectField,
  MultiSelect as MultiSelectComponent,
  DatePicker as DatePickerComponent,
  TimePicker as TimePickerComponent,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Component, FieldValue } from "server/src/types"
import { DeprecatedUpload } from "../DeprecatedUpload"
import dispatchToast from "@Utils/dispatchToast"
import { uploadHelper } from "@flash-hros/utility"
import {
  AccordionChildrenItems,
  IconContainer,
  PreviewDocContainer,
  PreviewImageContainer,
  PreviewTextContainer,
  RequiredLabel,
} from "./styles"
import { useContext } from "react"
import { Context } from "src/context"
import isEmpty from "lodash-es/isEmpty"

export interface RenderFieldProps {
  field: FieldValue
  companyId?: string
  candidateId?: string
  handleChange?: (value: any) => Promise<void>
  showError?: boolean
}

const File = ({
  field,
  handleChange,
  candidateId,
  companyId,
}: RenderFieldProps) => {
  const { token } = useContext(Context)

  const handleUploadFile = async ({
    name,
    file,
  }: {
    name: string
    file: File
  }): Promise<void> => {
    if (!handleChange || !companyId || !candidateId) return

    const fileName = `${Date.now() + "-" + field.placeholder.replace(" ", "_")}`
    const url = `${companyId}/${candidateId}/${field.customFieldId}/${fileName}`

    const { key, path } = await uploadHelper({
      key: url,
      file,
      fileName,
      module: "employee-pii",
      externalToken: token,
    })

    handleChange({ key, path, type: "internal" })
  }

  return field.value?.value ? (
    <PreviewDocContainer
      style={{ marginBottom: "24px" }}
      onClick={(e) => {
        e.stopPropagation()
        window.open(field.value?.value, "_blank")
      }}
    >
      <div style={{ display: "flex" }}>
        <PreviewImageContainer url={field.value?.value} />
        <PreviewTextContainer style={{ marginLeft: "10px" }}>
          <Typography variant="body3">{field.placeholder}</Typography>
        </PreviewTextContainer>
      </div>
      <IconContainer
        onClick={async (e: any) => {
          e.stopPropagation()
          e.preventDefault()

          if (handleChange && typeof handleChange === "function")
            await handleChange(null)
        }}
        style={{
          backgroundColor: "#fff",
          border: "1px solid #EBE5E9",
          marginLeft: "10px",
        }}
      >
        <Icons name="IconTrash" fill={"transparent"} />
      </IconContainer>
    </PreviewDocContainer>
  ) : (
    <AccordionChildrenItems>
      <DeprecatedUpload
        onChange={async ({ name, file }) => {
          await handleUploadFile({ name, file })
        }}
        label={field.placeholder}
        accept={["pdf", "png", "jpg", "jpeg"]}
        maxSize={5242880}
        onFileSizeError={() => {
          dispatchToast({
            content:
              "Arquivo maior que 5mb. Por favor, faça upload de arquivo menor que 5mb",
            type: "warning",
          })
        }}
      />
    </AccordionChildrenItems>
  )
}

const Checkbox = ({ field, handleChange }: RenderFieldProps) => {
  const isRequired = field.fieldRule?.required

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CheckboxComponent
        checked={field.value || false}
        onChange={async (e) => handleChange?.(e.target.checked)}
        style={{ marginRight: "20px" }}
      />
      <Typography variant="body3">
        {field.placeholder}
        {isRequired && <RequiredLabel> *</RequiredLabel>}
      </Typography>
    </div>
  )
}

const ShortText = ({ field, handleChange, showError }: RenderFieldProps) => {
  const isRequired = field.fieldRule?.required

  return (
    <div>
      <TextField
        defaultValue={field.value}
        onBlur={(e: any) => handleChange?.(e.target.value)}
        label={
          <>
            {field.placeholder}
            {isRequired && <RequiredLabel> *</RequiredLabel>}
          </>
        }
        type={"string"}
        placeholder={field.placeholder}
        fullWidth
        error={
          showError &&
          field.enabled &&
          field.fieldRule?.required &&
          isEmpty(field.value)
        }
        helperText={
          showError &&
          field.enabled &&
          field.fieldRule?.required &&
          isEmpty(field.value)
            ? "Preencha este campo"
            : ""
        }
      />
    </div>
  )
}

const LongText = ({ field, handleChange, showError }: RenderFieldProps) => {
  const isRequired = field.fieldRule?.required

  return (
    <div>
      <TextField
        defaultValue={field.value}
        label={
          <>
            {field.placeholder}
            {isRequired && <RequiredLabel> *</RequiredLabel>}
          </>
        }
        fullWidth
        type={"text"}
        onBlur={(e: any) => handleChange?.(e.target.value)}
        multiline={true}
        rows={20}
        error={
          showError &&
          field.enabled &&
          field.fieldRule?.required &&
          isEmpty(field.value)
        }
        helperText={
          showError &&
          field.enabled &&
          field.fieldRule?.required &&
          isEmpty(field.value)
            ? "Preencha este campo"
            : ""
        }
      />
    </div>
  )
}

const Number = ({ field, handleChange, showError }: RenderFieldProps) => {
  const isRequired = field.fieldRule?.required

  return (
    <div>
      <TextField
        defaultValue={field.value}
        onBlur={(e: any) => handleChange?.(e.target.value)}
        label={
          <>
            {field.placeholder}
            {isRequired && <RequiredLabel> *</RequiredLabel>}
          </>
        }
        fullWidth
        type={"number"}
        error={
          showError &&
          field.enabled &&
          field.fieldRule?.required &&
          isEmpty(field.value)
        }
        helperText={
          showError &&
          field.enabled &&
          field.fieldRule?.required &&
          isEmpty(field.value)
            ? "Preencha este campo"
            : ""
        }
      />
    </div>
  )
}

const Select = ({ field, handleChange, showError }: RenderFieldProps) => {
  const isRequired = field.fieldRule?.required

  const options = field.options
    ? field.options.map((option) => ({
        label: option,
        value: option,
      }))
    : []

  return (
    <SelectField
      value={field.value || undefined}
      label={
        <>
          {field.placeholder}
          {isRequired && <RequiredLabel> *</RequiredLabel>}
        </>
      }
      placeholder={field.placeholder}
      fullWidth
      required={isRequired}
      onSelectChange={(_, value) => handleChange?.(value?.value || "")}
      options={options}
      error={
        showError &&
        field.enabled &&
        field.fieldRule?.required &&
        isEmpty(field.value)
      }
      helperText={
        showError &&
        field.enabled &&
        field.fieldRule?.required &&
        isEmpty(field.value)
          ? "Preencha este campo"
          : ""
      }
    />
  )
}

const MultiSelect = ({ field, handleChange, showError }: RenderFieldProps) => {
  const isRequired = field.fieldRule?.required

  const options = field.options
    ? field.options.map((option) => ({
        label: option,
        value: option,
      }))
    : []

  return (
    <MultiSelectComponent
      renderInput={() => null}
      options={options}
      label={
        <>
          {field.placeholder}
          {isRequired && <RequiredLabel> *</RequiredLabel>}
        </>
      }
      value={field.value || undefined}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      onSelectChange={(_, value) => handleChange?.(value)}
      error={
        showError &&
        field.enabled &&
        field.fieldRule?.required &&
        isEmpty(field.value)
      }
      helperText={
        showError &&
        field.enabled &&
        field.fieldRule?.required &&
        isEmpty(field.value)
          ? "Preencha este campo"
          : ""
      }
    />
  )
}

const DatePicker = ({ field, handleChange, showError }: RenderFieldProps) => {
  const isRequired = field.fieldRule?.required

  return (
    <DatePickerComponent
      value={field.value}
      label={
        <>
          {field.placeholder}
          {isRequired && <RequiredLabel> *</RequiredLabel>}
        </>
      }
      required={isRequired}
      onDateChange={async (value) => {
        if (value?.toISOString() === field.value) return

        if (!value?.isValid()) {
          handleChange?.("")
          return
        }

        const date = value.toDate()
        handleChange?.(date.toISOString())
      }}
      fullWidth
      error={
        showError &&
        field.enabled &&
        field.fieldRule?.required &&
        isEmpty(field.value)
      }
      helperText={
        showError &&
        field.enabled &&
        field.fieldRule?.required &&
        isEmpty(field.value)
          ? "Preencha este campo"
          : ""
      }
    />
  )
}

const TimePicker = ({ field, handleChange, showError }: RenderFieldProps) => {
  const isRequired = field.fieldRule?.required

  return (
    <TimePickerComponent
      value={field.value}
      label={
        <>
          {field.placeholder}
          {isRequired && <RequiredLabel> *</RequiredLabel>}
        </>
      }
      onChange={(value) => handleChange?.(value)}
      error={
        showError &&
        field.enabled &&
        field.fieldRule?.required &&
        isEmpty(field.value)
      }
      helperText={
        showError &&
        field.enabled &&
        field.fieldRule?.required &&
        isEmpty(field.value)
          ? "Preencha este campo"
          : ""
      }
    />
  )
}

export const RenderField = (props: RenderFieldProps) => {
  switch (props.field.component) {
    case Component.Attachment:
      return <File {...props} />
    case Component.Checkbox:
      return <Checkbox {...props} />
    case Component.ShortText:
    case Component.Link:
      return <ShortText {...props} />
    case Component.LongText:
      return <LongText {...props} />
    case Component.Number:
      return <Number {...props} />
    case Component.Select:
      return <Select {...props} />
    case Component.MultiSelect:
      return <MultiSelect {...props} />
    case Component.DatePicker:
      return <DatePicker {...props} />
    case Component.TimePicker:
      return <TimePicker {...props} />
    default:
      return null
  }
}
