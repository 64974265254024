import { useContext, useEffect, useState } from "react"
import { Content, Start } from "./screens"
import { Context } from "src/context"
import { trpc } from "src/api/trpc"
import {
  Employee,
  ResignationCard,
  ResignationExamStatus,
  ResignationLimitDateEnum,
} from "server/src/types"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import dispatchToast from "@Utils/dispatchToast"
import { Loader } from "@flash-tecnologia/hros-web-ui-v2"

const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f8f6f8;
  overflow: hidden;
`

const LoadingArea = styled.div`
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`

enum StepEnum {
  initial,
  content,
}

export const ResignationExam = () => {
  const [step, setStep] = useState<StepEnum>(StepEnum.initial)

  const { cardId, employeeId, token } = useContext(Context)
  const navigate = useNavigate()

  const { data, isLoading } = trpc.wizard.getResignationExamData.useQuery(
    {
      cardId,
      employeeId,
    },
    {
      enabled: !!cardId && !!employeeId,
      onError: (_err) => {
        dispatchToast({
          content: "Algo aconteceu em buscar os dados do colaborador",
          type: "warning",
        })
        navigate("/wizard/invalid")
        return
      },
    }
  )

  useEffect(() => {
    if (!data) return

    const status = data.card.resignationExam?.status
    if (
      !status ||
      [ResignationExamStatus.pending, ResignationExamStatus.manual].includes(
        status
      )
    ) {
      navigate("/wizard/invalid")
      return
    }

    if (status === ResignationExamStatus.filled) {
      navigate("/wizard/finished?type=resignationAso")
      return
    }

    const emailLink = data.card.emailLink.find(
      (emailLink) => emailLink.token && token
    )
    if (!emailLink || emailLink?.expired) {
      navigate(`../wizard/invalid`, { replace: true })
      return
    }

    const limitDate = data?.card?.limitDates.find(
      (ld) => ld.step === ResignationLimitDateEnum.aso_Resignation
    )
    if (!limitDate || (limitDate && new Date() > new Date(limitDate.date)))
      navigate(`../wizard/invalid`, { replace: true })
  }, [data, navigate])

  if (!data || isLoading) {
    return (
      <MainContainer>
        <LoadingArea>
          <Loader variant="primary" size="large" />
        </LoadingArea>
      </MainContainer>
    )
  }

  switch (step) {
    case StepEnum.initial:
      return (
        <Start
          status={data.card.resignationExam.status || "sent"}
          handleNextStep={() => setStep(StepEnum.content)}
        />
      )
    case StepEnum.content:
      return (
        <Content
          card={data.card as ResignationCard}
          employee={data.employee as Employee}
        />
      )
  }
}
